// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import { KeycloakConfig } from "keycloak-js";
import "zone.js/dist/zone-error"; // Included with Angular CLI.

let keycloakConfig: KeycloakConfig = {
  url: "https://auth.ecotx.dev",
  realm: "eco-realm",
  clientId: "ecotx-backoffice",
};

let powerBiConfig = {
  reportBaseURL: "https://app.powerbi.com/reportEmbed",
  qnaBaseURL: "https://app.powerbi.com/qnaEmbed",
  tileBaseURL: "https://app.powerbi.com/embed",
  groupID: "3aca5f3d-d7af-4311-a3c7-89f8716479bc",
  reportID: "619c668f-9be0-4589-ae6d-3f456847eeb2",
};

export const environment = {
  production: true,
  appVersion: "ecotx-backoffice.v1",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: false,
  mediaUrl: "/mediaService",
  apiUrl: "https://platform-service.ecotx.dev/api",
  apiGraphqlUrl: "https://platform-service.ecotx.dev/graphql",
  userProfileApiUrl: "https://user.ecotx.dev",
  mapServicesUrl: "https://map-services.ecotx.dev",
  paymentsApiUrl: "https://payments.ecotx.dev",
  keycloak: keycloakConfig,
  powerBi: powerBiConfig,
  ecotxPlatformUrl: "https://platform.ecotx.dev",
  eMapUrl: "https://map.ecotx.dev",
  mediaServiceUrl: "https://media-service.ecotx.dev",
  mediaFileUrl: "https://geoserver.ecotx.dev/storage/media",
  ecotxNotificationsWSS: 'wss://notifications.ecotx.dev/websocket',
  elasticSearchServiceApiUrl: 'https://layer-service-data.ecotx.dev',
  assetsReportUrl: 'http://localhost:8080'
};